import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlogFeed from "../components/blog-feed"
import NavbarBlog from "../components/navbar-blog"
import SubscribeBlog from "../components/subscribe-blog"
import BlogPagination from "../components/blog-pagination"
import { ITEMS_PER_PAGE_FEED } from "../utils/constants"
import { getPath } from "../utils/gatsby-node-helpers"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query CategoryPaginationQuery(
    $lang: String
    $limit: Int
    $skip: Int
    $uid: String
    $uids: [String!]
  ) {
    prismicBlogHome(lang: { eq: $lang }) {
      data {
        continue_reading_button
        description_meta
        image_meta {
          url
        }
        keywords_meta
        subscribe_button
        subscribe_image {
          url
        }
        subscribe_text
        subscribe_title
        title_meta
      }
    }
    allPrismicBlogPost(
      filter: { lang: { eq: $lang }, uid: { in: $uids } }
      sort: { fields: first_publication_date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      nodes {
        data {
          category {
            uid
          }
          cover {
            url
          }
          title {
            text
          }
          title_meta
          body {
            ... on PrismicBlogPostBodyContentBlog {
              primary {
                content_blog {
                  html
                  raw
                  text
                }
              }
              slice_type
            }
          }
        }
        first_publication_date(locale: $lang)
        uid
        lang
      }
    }
    prismicCategory(lang: { eq: $lang }, uid: { eq: $uid }) {
      uid
      data {
        category
      }
    }
  }
`

const CategoryTemplate = ({ data, pageContext, uri, location }) => {
  const { lang: locale, totalCount, page } = pageContext
  const maxPage = Math.ceil(totalCount / ITEMS_PER_PAGE_FEED)
  const home = data.prismicBlogHome.data
  const category = data.prismicCategory?.data.category
  const categoryUid = data.prismicCategory?.uid
  const feed = data.allPrismicBlogPost.nodes
  if (!home || !feed) return null
  return (
    <Layout mainPage="companies" locale={locale} path={location?.pathname}>
      <NavbarBlog
        category={category}
        locale={locale}
        url={location?.pathname}
      />
      <main className="blog pt-6 pt-md-7">
        <div className="container ">
          <h3 className="section-title mb-4">
            <div className="d-flex align-items-end">
              <div className="flex-grow-1">{category}</div>
              <small className="text-muted font-weight-light">
                {totalCount} Post{totalCount !== 1 ? "s" : ""}
              </small>
            </div>
          </h3>
          <BlogFeed posts={feed} locale={locale} />
          {totalCount > ITEMS_PER_PAGE_FEED && (
            <BlogPagination
              currentPage={Number(page)}
              maxPage={maxPage}
              pathname={getPath({
                docType: "category",
                lang: locale,
                uid: categoryUid,
              })}
            />
          )}
        </div>
      </main>
      <SubscribeBlog home={home} locale={locale} />
    </Layout>
  )
}

export default withPreview(CategoryTemplate)
